import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import contractModels from '../../../constants/contractModels';
import Autocomplete from '../../../components/Autocomplete';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';

import gateway from '../../../utils/gateway';
import { showError } from '../../../utils/toast';

const ProposalSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Nome deve conter até 255 caracteres'),
  contract_type: Yup.number().required('Campo obrigatório'),
  tenant_owner: Yup.object()
    .nullable()
    .when('contract_type', {
      is: 2,
      then: Yup.object().required('Campo obrigatório'),
    }),
});

const useStyles = makeStyles(() => ({
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const TenantStep = ({
  show,
  step,
  total,
  onNextStep,
  context,
  label,
  guided,
  spot,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [tenant, setTenant] = React.useState(context.tenant || null);

  const formRef = React.useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const fetchTenants = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/tenants', {
        params: {
          search,
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((tenant) => ({
        ...tenant,
        value: tenant.id,
        label: tenant.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const fetchContracts = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/contracts', {
        params: {
          search,
          status: [4],
          ...(tenant !== null ? { tenant_id: tenant.id } : {}),
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((contract) => ({
        ...contract,
        value: contract.id,
        label: contract.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  const fetchPipedriveDeals = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/pipedrive/deals', {
        params: {
          search,
        },
      });

      options = response.data.map((deal) => ({
        value: deal.id,
        label: deal.title,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  if (!show) {
    return null;
  }

  const existingTenant = !!context.tenant;

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">
              {label || 'Selecionar Cliente'}
            </Typography>
          </Box>
          <Box p={4} pt={4} pb={2} display="flex" flexDirection="column">
            <Formik
              innerRef={formRef}
              validateOnMount
              initialValues={{
                tenant: context.tenant || null,
                contract: context.contract || null,
                deal: context.deal_key || null,
                contract_type: spot ? 6 : 0,
                payment_provider: 1,
                tenant_owner: context.tenant_owner || null,
                name: context.name || context.tenant?.name || '',
              }}
              validationSchema={ProposalSchema}
              onSubmit={async (values) => {
                onNextStep({
                  ...context,
                  ...values,
                  deal_key: values.deal,
                });
              }}
              render={({ values, errors, touched, setFieldValue, isValid }) => {
                return (
                  <>
                    <form>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a cliente da base
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="tenant"
                            label="Cliente"
                            placeholder="Cliente"
                            component={Autocomplete}
                            fetch={fetchTenants}
                            disabled={guided || existingTenant}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum cliente encontrado"
                            error={errors.tenant && touched.tenant}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('tenant', value);
                              setFieldValue('contract', null);

                              if (value) {
                                setTenant(value);
                                setFieldValue('name', value.name);
                              } else {
                                setTenant(null);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a contrato
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="contract"
                            label="Contrato"
                            placeholder="Contrato"
                            component={Autocomplete}
                            fetch={fetchContracts}
                            disabled={guided || existingTenant}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum contrato encontrado"
                            error={errors.contract && touched.contract}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('contract', value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Vincular proposta a negócio do pipedrive
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="deal"
                            label="Negócio"
                            placeholder="Negócio"
                            component={Autocomplete}
                            fetch={fetchPipedriveDeals}
                            loadingText="Carregando..."
                            noOptionsText="Nenhum negócio encontrado"
                            error={errors.deal && touched.deal}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('deal', value);

                              if (value) {
                                setFieldValue('name', value.label);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">Tipo de contrato</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              disabled={existingTenant || spot}
                              name="contract_type"
                              label="Tipo de contrato"
                              placeholder="Tipo de contrato"
                              component={SelectField}
                              options={contractModels}
                              error={
                                errors.contract_type && touched.contract_type
                              }
                            />
                          </Grid>
                        </Grid>

                        {values.contract_type === 2 && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Empresa franqueadora
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                name="tenant_owner"
                                label="Franqueador"
                                placeholder="Franqueador"
                                component={Autocomplete}
                                fetch={fetchTenants}
                                loadingText="Carregando..."
                                noOptionsText="Nenhuma empresa encontrada"
                                error={
                                  errors.tenant_owner && touched.tenant_owner
                                }
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setFieldValue('tenant_owner', value);
                                }}
                                helperText={
                                  errors.tenant_owner && touched.tenant_owner
                                    ? errors.tenant_owner
                                    : ''
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Identificação da proposta
                          </Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              name="name"
                              label="Nome"
                              placeholder="Nome"
                              component={TextField}
                              disabled={
                                values.tenant !== null || values.deal !== null
                              }
                              error={errors.name && touched.name}
                              helperText={
                                errors.name && touched.name ? errors.name : ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                    <Box
                      pt={2}
                      pb={0}
                      pl={4}
                      pr={4}
                      mt={4}
                      display="flex"
                      justifyContent="space-between"
                      borderTop="1px solid #ededed"
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => history.goBack()}
                        className={classes.cancel}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.confirm}
                        endIcon={<ChevronRightIcon />}
                        disabled={!isValid}
                      >
                        Próximo
                      </Button>
                    </Box>
                  </>
                );
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default TenantStep;
