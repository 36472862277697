import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { showError } from '../../../utils/toast';
import EditItemDialog from '../EditItemDialog';

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: 8,
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const ButtonTableCell = withStyles(() => ({
  root: {
    width: 1,
    padding: 0,
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const COLUMNS = [
  {
    id: 'product',
    label: 'PRODUTO',
  },
  { id: 'type', label: 'Tipo.' },
  { id: 'quantity', label: 'QTD.' },
  { id: 'price', label: 'PREÇO' },
  { id: 'discount', label: 'DESCONTO' },
  { id: 'total', align: 'right', label: 'TOTAL' },
  { id: 'remove', align: 'center', label: '' },
  { id: 'edit', align: 'center', label: '' },
];

const TYPE = {
  0: { label: 'SAAS' },
  1: { label: 'Serviços' },
  2: { label: 'Outros' },
};

const ItemsStep = ({
  show,
  step,
  total,
  onPreviousStep,
  onNextStep,
  context,
  label,
  spot,
  recurrent,
  amendment,
  addSubscription,
}) => {
  const classes = useStyles();

  const [showEditItemDialog, setShowEditItemDialog] = React.useState(false);
  const [isEditDialog, setIsEditDialog] = React.useState(false);
  const [item, setItem] = React.useState(null);
  const [contractType, setContractType] = React.useState(context.contract_type);
  const [items, setItems] = React.useState(context.proposal_items || []);

  React.useEffect(
    () => setContractType(addSubscription ? 0 : context.contract_type),
    [context],
  );

  if (!show) {
    return null;
  }

  const handleDeleteItem = (id) => {
    return () => {
      const newItems = [...items];
      const index = items.findIndex((row) => row.id === id);
      newItems.splice(index, 1);
      setItems(newItems);
    };
  };

  const handleShowEditModal = (id) => {
    return () => {
      const item = items.find((row) => row.id === id);
      setItem(item);
      setShowEditItemDialog(true);
      setIsEditDialog(true);
    };
  };

  const handleSubmitItem = (data) => {
    if (item === null) {
      const newItem = { ...data, id: items.length };
      const newItems = [...items, newItem];
      setItems(newItems);
    } else {
      const newItems = [...items];
      const index = items.findIndex((row) => row.id === item.id);
      newItems[index] = { ...data, id: item.id };
      setItems(newItems);
    }

    setItem(null);
    setShowEditItemDialog(false);
  };

  const totalRecurrent = items.reduce((sum, item) => {
    if (
      item.contract_product.recurrency_type === 1 ||
      item.contract_product.recurrency_type === 2
    ) {
      return sum + item.total;
    }
    return sum;
  }, 0);

  const formattedTotalRecurrent = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalRecurrent);

  const totalSpot = items.reduce((sum, item) => {
    if (
      item.contract_product.recurrency_type === 0 ||
      item.contract_product.recurrency_type === 3
    ) {
      return sum + item.total;
    }
    return sum;
  }, 0);

  const formattedTotalSpot = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalSpot);

  const monthlyItemsCount = items.reduce((sum, item) => {
    if (item.contract_product.recurrency_type === 1) {
      return sum + 1;
    }
    return sum;
  }, 0);

  const annuallyItemsCount = items.reduce((sum, item) => {
    if (item.contract_product.recurrency_type === 2) {
      return sum + 1;
    }
    return sum;
  }, 0);

  const invalidItemsCombination =
    monthlyItemsCount > 0 && annuallyItemsCount > 0;

  const onNextPress = () => {
    if (invalidItemsCombination) {
      showError(
        'A proposta não pode conter itens de recorrência anual e mensal.',
      );
    } else {
      let recurrency_type = 0;
      if (items.some((item) => item.contract_product.recurrency_type === 1)) {
        recurrency_type = 1;
      } else if (
        items.some((item) => item.contract_product.recurrency_type === 2)
      ) {
        recurrency_type = 2;
      }

      onNextStep({
        ...context,
        recurrency_type,
        proposal_items: items,
      });
    }
  };

  return (
    <>
      <Fade in>
        <Paper square elevation={2} style={{ flex: 1 }}>
          <Box p={0} display="flex" flexDirection="column">
            <Box p={4} pt={6} display="flex" flexDirection="column">
              <Typography variant="subtitle2" color="primary">
                Passo {step + 1} de {total}
              </Typography>
              <Typography variant="h4">{label || 'Adicionar Itens'}</Typography>
            </Box>
            <Box p={4} pt={6} pb={8} display="flex" flexDirection="column">
              <Box mb={4} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowEditItemDialog(true);
                    setItem(null);
                  }}
                  className={classes.button}
                  startIcon={<AddIcon />}
                >
                  Novo Item
                </Button>
              </Box>
              {invalidItemsCombination && (
                <Box mb={4}>
                  <Alert severity="error">
                    A proposta não pode conter itens de recorrência anual e
                    mensal.
                  </Alert>
                </Box>
              )}
              <Box mb={2} display="flex" flexDirection="column">
                <Typography variant="h6">Itens recorrentes</Typography>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        {COLUMNS.map((column) => (
                          <ItemsTableCell
                            key={column.id}
                            align={column.align ? column.align : 'left'}
                          >
                            <Typography variant="subtitle2" color="primary">
                              {column.label}
                            </Typography>
                          </ItemsTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items
                        .filter(
                          (item) =>
                            item.contract_product.recurrency_type === 1 ||
                            item.contract_product.recurrency_type === 2,
                        )
                        .map((item) => {
                          const formattedDiscount = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.discount);

                          const formattedPrice = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.price);

                          const formattedTotal = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.total);

                          let measure = '';
                          if (item.contract_product.measure === 1) {
                            measure = ' / mês';
                          } else if (item.contract_product.measure === 2) {
                            measure = ' / hora';
                          } else if (item.contract_product.measure === 3) {
                            measure = ' / usuário';
                          } else if (item.contract_product.measure === 4) {
                            measure = ' / usuário / mês';
                          } else if (item.contract_product.measure === 5) {
                            measure = ' / ano';
                          } else if (item.contract_product.measure === 6) {
                            measure = ' / usuário / ano';
                          }

                          let recurrency = '';
                          if (item.contract_product.recurrency_type === 1) {
                            recurrency = ' / mês';
                          } else if (
                            item.contract_product.recurrency_type === 2
                          ) {
                            recurrency = ' / ano';
                          }

                          const updatedQuantity =
                            item.original &&
                            item.original.quantity !== item.quantity;
                          const updatedPrice =
                            item.original && item.original.price !== item.price;
                          const updatedDiscount =
                            item.original &&
                            (item.original.discount_type !==
                              item.discount_type ||
                              item.original.discount !== item.discount);
                          const updatedTotal =
                            item.original && item.original.total !== item.total;

                          return (
                            <TableRow
                              tabIndex={-1}
                              key={item.id}
                              style={{ opacity: item.remove ? 0.25 : 1 }}
                            >
                              <ItemsTableCell>
                                <Box display="flex" alignItems="center">
                                  <Typography>
                                    {item.contract_product.name}
                                  </Typography>
                                </Box>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Box display="flex" alignItems="left">
                                  <Typography>
                                    {TYPE[item.contract_product.type].label}
                                  </Typography>
                                </Box>
                              </ItemsTableCell>
                              <ItemsTableCell align="left">
                                {updatedQuantity && (
                                  <Typography
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {item.original.quantity}
                                  </Typography>
                                )}
                                <Typography>{item.quantity}</Typography>
                              </ItemsTableCell>
                              <ItemsTableCell align="left">
                                {updatedPrice && (
                                  <Typography
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {Intl.NumberFormat('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }).format(item.original.price) + measure}
                                  </Typography>
                                )}
                                <Typography>
                                  {formattedPrice + measure}
                                </Typography>
                              </ItemsTableCell>
                              <ItemsTableCell align="left">
                                {updatedDiscount &&
                                  item.original.discount_type === 0 && (
                                    <Typography
                                      style={{ textDecoration: 'line-through' }}
                                    >
                                      {item.original.discount}%
                                    </Typography>
                                  )}
                                {updatedDiscount &&
                                  item.original.discount_type === 1 && (
                                    <Typography
                                      style={{ textDecoration: 'line-through' }}
                                    >
                                      {item.original.discount}
                                    </Typography>
                                  )}
                                {item.discount_type === 0 && (
                                  <Typography>{item.discount}%</Typography>
                                )}
                                {item.discount_type === 1 && (
                                  <Typography>{formattedDiscount}</Typography>
                                )}
                              </ItemsTableCell>
                              <ItemsTableCell align="right">
                                {updatedTotal && (
                                  <Typography
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {Intl.NumberFormat('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }).format(item.original.total) + recurrency}
                                  </Typography>
                                )}
                                <Typography>
                                  {formattedTotal + recurrency}
                                </Typography>
                              </ItemsTableCell>
                              <ButtonTableCell align="center">
                                <IconButton
                                  aria-label="deletar"
                                  onClick={handleDeleteItem(item.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ButtonTableCell>
                              <ButtonTableCell align="center">
                                <IconButton
                                  aria-label="editar"
                                  onClick={handleShowEditModal(item.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </ButtonTableCell>
                            </TableRow>
                          );
                        })}
                      {items.filter(
                        (item) =>
                          item.contract_product.recurrency_type === 1 ||
                          item.contract_product.recurrency_type === 2,
                      ).length === 0 && (
                        <TableRow>
                          <ItemsTableCell
                            colSpan={COLUMNS.length}
                            align="center"
                          >
                            <Box p={6}>
                              <Typography>
                                Nenhum item recorrente selecionado
                              </Typography>
                            </Box>
                          </ItemsTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display="flex" flexDirection="row-reverse" p={4}>
                  <Typography variant="subtitle1">
                    Total {`${formattedTotalRecurrent}`}
                  </Typography>
                </Box>
              </Box>
              {addSubscription ? null : (
                <>
                  <Typography variant="h6">Itens Spot</Typography>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        <TableRow>
                          {COLUMNS.map((column) => (
                            <ItemsTableCell
                              key={column.id}
                              align={column.align ? column.align : 'left'}
                            >
                              <Typography variant="subtitle2" color="primary">
                                {column.label}
                              </Typography>
                            </ItemsTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items
                          .filter(
                            (item) =>
                              item.contract_product.recurrency_type === 0 ||
                              item.contract_product.recurrency_type === 3,
                          )
                          .map((item) => {
                            const formattedDiscount = Intl.NumberFormat(
                              'pt-BR',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              },
                            ).format(item.discount);

                            const formattedPrice = Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.price);

                            const formattedTotal = Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.total);

                            let measure = '';
                            if (item.contract_product.measure === 1) {
                              measure = ' / mês';
                            } else if (item.contract_product.measure === 2) {
                              measure = ' / hora';
                            } else if (item.contract_product.measure === 3) {
                              measure = ' / usuário';
                            } else if (item.contract_product.measure === 4) {
                              measure = ' / usuário / mês';
                            } else if (item.contract_product.measure === 5) {
                              measure = ' / ano';
                            } else if (item.contract_product.measure === 6) {
                              measure = ' / usuário / ano';
                            }

                            let recurrency = '';
                            if (item.contract_product.recurrency_type === 1) {
                              recurrency = ' / mês';
                            } else if (
                              item.contract_product.recurrency_type === 2
                            ) {
                              recurrency = ' / ano';
                            }

                            return (
                              <TableRow tabIndex={-1} key={item.id}>
                                <ItemsTableCell>
                                  <Box display="flex" alignItems="center">
                                    <Typography>
                                      {item.contract_product.name}
                                    </Typography>
                                  </Box>
                                </ItemsTableCell>
                                <ItemsTableCell>
                                  <Box display="flex" alignItems="left">
                                    <Typography>
                                      {TYPE[item.contract_product.type].label}
                                    </Typography>
                                  </Box>
                                </ItemsTableCell>
                                <ItemsTableCell>
                                  <Typography>{item.quantity}</Typography>
                                </ItemsTableCell>
                                <ItemsTableCell>
                                  <Typography>
                                    {formattedPrice + measure}
                                  </Typography>
                                </ItemsTableCell>
                                <ItemsTableCell>
                                  {item.discount > 0 &&
                                    item.discount_type === 0 && (
                                      <Typography>{item.discount}%</Typography>
                                    )}
                                  {item.discount > 0 &&
                                    item.discount_type === 1 && (
                                      <Typography>
                                        {formattedDiscount}
                                      </Typography>
                                    )}
                                </ItemsTableCell>
                                <ItemsTableCell align="right">
                                  <Typography>
                                    {formattedTotal + recurrency}
                                  </Typography>
                                </ItemsTableCell>
                                <ButtonTableCell align="center">
                                  <IconButton
                                    aria-label="deletar"
                                    onClick={handleDeleteItem(item.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ButtonTableCell>
                                <ButtonTableCell align="center">
                                  <IconButton
                                    aria-label="editar"
                                    onClick={handleShowEditModal(item.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </ButtonTableCell>
                              </TableRow>
                            );
                          })}
                        {items.filter(
                          (item) =>
                            item.contract_product.recurrency_type === 0 ||
                            item.contract_product.recurrency_type === 3,
                        ).length === 0 && (
                          <TableRow>
                            <ItemsTableCell
                              colSpan={COLUMNS.length}
                              align="center"
                            >
                              <Box p={6}>
                                <Typography>
                                  Nenhum item spot selecionado
                                </Typography>
                              </Box>
                            </ItemsTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box display="flex" flexDirection="row-reverse" p={4}>
                    <Typography variant="subtitle1">
                      Total {`${formattedTotalSpot}`}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box
              p={2}
              pl={4}
              pr={4}
              display="flex"
              justifyContent="space-between"
              borderTop="1px solid #ededed"
            >
              <Button
                color="default"
                onClick={() =>
                  onPreviousStep({
                    ...context,
                    proposal_items: items,
                  })
                }
                className={classes.cancel}
                startIcon={<ChevronLeftIcon />}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onNextPress}
                className={classes.confirm}
                disabled={items && items.length === 0}
                endIcon={<ChevronRightIcon />}
              >
                Próximo
              </Button>
            </Box>
          </Box>
        </Paper>
      </Fade>
      {contractType !== undefined && (
        <EditItemDialog
          open={showEditItemDialog}
          edit={isEditDialog}
          onClose={() => setShowEditItemDialog(false)}
          onSubmit={handleSubmitItem}
          item={isEditDialog ? item : null}
          contractType={contractType}
          spot={spot}
          recurrent={recurrent}
          amendment={amendment}
          addSubscription={addSubscription}
        />
      )}
    </>
  );
};

export default ItemsStep;
