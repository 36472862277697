import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import Wizard from '../../components/Wizard/Wizard';

import gateway from '../../utils/gateway';
import { showError } from '../../utils/toast';

import TenantStep from './Steps/TenantStep';
import ItemsStep from './Steps/ItemsStep';
import ProposalStep from './Steps/ProposalStep';
import ReviewStep from './Steps/ReviewStep';

const EditProposalWizard = () => {
  const params = useParams();

  const [loading, setLoading] = React.useState(true);
  const [proposal, setProposal] = React.useState({});

  const fetchProposal = async () => {
    setLoading(true);

    try {
      const response = await gateway.get(`/billing/proposals/${params.id}`);

      let { proposal } = response.data;
      const items = proposal.proposal_items.map((item) => ({
        ...item,
        contract_product: {
          ...item.contract_product,
          value: item.contract_product.id,
          label: item.contract_product.name,
        },
        observations: item.observations
          ? JSON.stringify(convertToRaw(stateFromHTML(item.observations)))
          : '',
      }));
      proposal = {
        ...proposal,
        proposal_items: items,
        deal_key:
          proposal.deal_key !== null
            ? {
                value: proposal.deal_key,
                label: proposal.name,
              }
            : null,
        observations: proposal.observations
          ? JSON.stringify(convertToRaw(stateFromHTML(proposal.observations)))
          : '',
      };

      setProposal(proposal);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProposal();
  }, []);

  if (loading) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={84} />
      </Box>
    );
  }

  return (
    <Fade in>
      <Wizard context={proposal}>
        <TenantStep label="Selecionar Cliente" guided />
        <ItemsStep
          label="Adicionar Itens"
          spot={proposal && proposal.type === 1}
          recurrent={proposal && (proposal.type === 2 || proposal.type === 3)}
        />
        <ProposalStep label="Proposta" />
        <ReviewStep
          label="Revisão"
          update
          first={proposal && proposal.type === 0}
          spot={proposal && proposal.type === 1}
          amendment={proposal && proposal.type === 2}
          contract={proposal && proposal.type === 3}
        />
      </Wizard>
    </Fade>
  );
};

export default EditProposalWizard;
