import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import moment from 'moment';

import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import CheckBoxField from '../../components/CheckBoxField';
import DateField from '../../components/DateField';
import Autocomplete from '../../components/Autocomplete';

import { PermissionContext } from '../../contexts/PermissionContext';
import gateway from '../../utils/gateway';
import { buildImageUrl } from '../../utils/url';
import { showError } from '../../utils/toast';
import PaymentProvider from '../../components/PaymentProvider';

const COLUMNS = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'tenant',
    label: 'CLIENTE',
  },
  { id: 'document', label: 'CPF/CNPJ' },
  { id: 'ONBOARDING', align: 'left', label: 'ONB.' },
  { id: 'delinquent', label: 'PROX. VENC.' },
  { id: 'partner', label: 'REVENDA' },
  { id: 'user', label: 'ÚLTIMO LOGIN' },
  {
    id: 'created',
    align: 'right',
    label: 'CRIADO EM',
  },
];

const ItemsTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: '4px 8px',
    border: 0,
    '&:first-child': {
      padding: '4px 8px 4px 0',
    },
    '&:last-child': {
      padding: '4px 0 8px 8px',
    },
  },
}))(TableCell);

const TenantsPage = () => {
  const history = useHistory();
  const theme = useTheme();

  const { authorized } = React.useContext(PermissionContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(false);
  const [tenants, setTenants] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [search, setSearch] = React.useState('');
  const [startsAt, setStartsAt] = React.useState(null);
  const [endsAt, setEndsAt] = React.useState(null);
  const [status, setStatus] = React.useState(-1);
  const [onboardingOnly, setOnboardingOnly] = React.useState(true);
  const [delinquentOnly, setDelinquentOnlyOnly] = React.useState(false);
  const [iuguOnly, setIugutOnlyOnly] = React.useState(false);
  const [partner, setPartner] = React.useState([]);
  const [filters, setFilters] = React.useState({
    search: '',
    startsAt: null,
    endsAt: null,
    status: -1,
    onboardingOnly: true,
    delinquentOnly: false,
    iuguOnly: false,
    partner,
  });

  const handleClick = (id) => {
    window.open(`/tenants/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setFilters({
      search,
      startsAt,
      endsAt,
      status,
      onboardingOnly,
      delinquentOnly,
      iuguOnly,
      partner,
    });
  };

  const fetchTenants = async () => {
    setLoading(true);

    try {
      const response = await gateway.get('/billing/tenants', {
        params: {
          ...(filters.search.length > 0 ? { search: filters.search } : {}),
          status: filters.status === -1 ? [0, 1] : [filters.status],
          onboarding_only: filters.onboardingOnly,
          delinquent_only: filters.delinquentOnly,
          iugu_only: filters.iuguOnly,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ...(filters.partner ? { partners: filters.partner } : undefined),
          ...(filters.startsAt !== null ? { starts_at: filters.startsAt } : {}),
          ...(filters.endsAt !== null ? { ends_at: filters.endsAt } : {}),
        },
      });

      setTenants(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPartners = async (search) => {
    let options = [];
    try {
      const response = await gateway.get('/billing/partners', {
        params: {
          search,
          offset: 0,
          limit: 100,
        },
      });

      options = response.data.data.map((partner) => ({
        value: partner.id,
        label: partner.name,
      }));
    } catch (err) {
      console.error(err);
      showError();
    }

    return options;
  };

  React.useEffect(() => {
    fetchTenants();
  }, [rowsPerPage, page, filters]);

  return (
    <Fade in>
      <Box flex={1}>
        <Paper square elevation={2}>
          <Box pt={6} p={4}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">Clientes</Typography>
              {authorized('tenants.add') && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => history.push('/tenants/new')}
                >
                  Novo cliente
                </Button>
              )}
            </Box>
            <Box pt={4} display="flex" alignItems="center">
              <Box pr={4}>
                <TextField
                  name="search"
                  label="Pesquisar"
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="inherit" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Box>
              <Box pr={4} width={160}>
                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Todos', value: -1 },
                    { label: 'Ativos', value: 1 },
                    { label: 'Inativos', value: 0 },
                  ]}
                  variant="standard"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Box pr={4} width={200}>
                  <DateField
                    name="startsAt"
                    label="Início"
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    variant="standard"
                    disableToolbar
                    value={startsAt}
                    onChange={(e) => {
                      setStartsAt(e.target.value);
                    }}
                  />
                </Box>
                <Box pr={4} width={200}>
                  <DateField
                    name="endsAt"
                    label="Fim"
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    variant="standard"
                    disableToolbar
                    value={endsAt}
                    onChange={(e) => {
                      setEndsAt(e.target.value);
                    }}
                  />
                </Box>
              </Box>
              {authorized('partners.view') && (
                <Box pr={4}>
                  <Autocomplete
                    name="partner"
                    label="Revenda"
                    placeholder="Revenda"
                    fetch={fetchPartners}
                    loadingText="Carregando..."
                    noOptionsText="Nenhuma revenda encontrada"
                    variant="standard"
                    value={partner}
                    multiple
                    style={{ width: 260 }}
                    onChange={(e) => {
                      setPartner(e.target.value);
                    }}
                  />
                </Box>
              )}
              <Box pr={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  Buscar
                </Button>
              </Box>
            </Box>
            <Box pt={2} display="flex" alignItems="center">
              <Box pr={4}>
                <CheckBoxField
                  name="onboardingOnly"
                  label="Exibir clientes do novo onboarding"
                  checked={onboardingOnly}
                  onChange={(e) => {
                    setOnboardingOnly(e.target.checked);
                  }}
                />
              </Box>
              <Box pr={4}>
                <CheckBoxField
                  name="delinquentOnly"
                  label="Apenas atrasados"
                  checked={delinquentOnly}
                  onChange={(e) => {
                    setDelinquentOnlyOnly(e.target.checked);
                  }}
                />
              </Box>
              <Box pr={4}>
                <CheckBoxField
                  name="iugutOnly"
                  label="Apenas Iugu"
                  checked={iuguOnly}
                  onChange={(e) => {
                    setIugutOnlyOnly(e.target.checked);
                  }}
                />
              </Box>
            </Box>
            <Box pt={4}>
              <TableContainer>
                <MuiTable
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      {COLUMNS.map((column) => (
                        <ItemsTableCell
                          key={column.id}
                          align={column.align ? column.align : 'left'}
                        >
                          <Typography variant="subtitle2" color="primary">
                            {column.label}
                          </Typography>
                        </ItemsTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      [1, 2, 3].map((value) => (
                        <TableRow tabIndex={-1} key={value}>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell>
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                          <ItemsTableCell align="right">
                            <Skeleton variant="text" />
                          </ItemsTableCell>
                        </TableRow>
                      ))}
                    {!loading &&
                      tenants.map((row) => {
                        return (
                          <ItemsTableRow
                            hover
                            onClick={() => handleClick(row.id)}
                            key={row.id}
                          >
                            <ItemsTableCell align="left">
                              {row.id}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              <Box
                                display="flex"
                                alignItems="center"
                                width={200}
                              >
                                <Badge
                                  overlap="circle"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  badgeContent={
                                    <Box
                                      width={12}
                                      height={12}
                                      borderRadius={6}
                                      bgcolor={
                                        row.status
                                          ? theme.palette.success[500]
                                          : theme.palette.danger[500]
                                      }
                                    />
                                  }
                                >
                                  <Avatar
                                    alt={row.name}
                                    src={buildImageUrl({
                                      folder: 'tenants',
                                      size: 96,
                                      name: row.image,
                                    })}
                                  >
                                    {row.label
                                      .replace(/[^a-zA-Z ]/g, '')
                                      .trim()
                                      .split(' ')
                                      .map((word) => word[0])
                                      .join('')
                                      .substring(0, 2)
                                      .toUpperCase()}
                                  </Avatar>
                                </Badge>
                                <Box
                                  ml={2}
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <Typography variant="subtitle1">
                                    {row.label}
                                  </Typography>
                                  <Typography variant="body2">
                                    {row.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </ItemsTableCell>
                            <ItemsTableCell>{row.document}</ItemsTableCell>
                            <ItemsTableCell align="left">
                              {row.contract_id !== null && (
                                <CheckCircleIcon color="primary" />
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              <Box display="flex" flexDirection="column">
                                {row?.contract?.payment_provider === 1 && (
                                  <Typography variant="subtitle1">
                                    {row?.contract?.expires_at
                                      ? moment(row.contract.expires_at).format(
                                          'DD/MM/YYYY',
                                        )
                                      : ''}
                                  </Typography>
                                )}

                                <Box display="flex">
                                  {row.contract && (
                                    <PaymentProvider
                                      paymentProvider={
                                        row.contract.payment_provider
                                      }
                                    />
                                  )}
                                  {row.delinquent &&
                                    row.iugu_subscription_id && (
                                      <EventBusyIcon color="error" />
                                    )}
                                </Box>
                              </Box>
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.partner ? row.partner.name : ''}
                            </ItemsTableCell>
                            <ItemsTableCell>
                              {row.last_login.last_login_at ? (
                                <Box flexDirection="column">
                                  <Typography variant="body2">
                                    {moment(
                                      row.last_login.last_login_at,
                                    ).format('DD/MM/YYYY HH:mm')}
                                  </Typography>
                                  <Typography variant="body2">
                                    {row.last_login.login}
                                  </Typography>
                                </Box>
                              ) : (
                                ''
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              {moment(row.created).format('DD/MM/YYYY HH:mm')}
                            </ItemsTableCell>
                          </ItemsTableRow>
                        );
                      })}
                    {!loading && tenants.length === 0 && (
                      <TableRow>
                        <ItemsTableCell colSpan={COLUMNS.length} align="center">
                          <Box m={4}>
                            <Typography>Nenhum cliente encontrado</Typography>
                          </Box>
                        </ItemsTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </MuiTable>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Resultados por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count}`
                }
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};

export default TenantsPage;
